import Map from '@components/map/Map';
import { locationsData } from '@data/home/locations';

export default function RedesImatLoc() {
  const { RedesImatPlaces } = locationsData;
  return (
    <>
      <Map places={RedesImatPlaces} />
    </>
  );
}
