import { createContext, useState } from 'react';
import { AuthContextType, ChildrenProps } from '@interfaces/index';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthContextProvider({ children }: ChildrenProps) {
  const [errors, setErrors] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <AuthContext.Provider value={{ errors, setErrors, isOpen, setIsOpen }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
