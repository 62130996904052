import heart from '@assets/iconify/benefics/heart-health.svg';
import bagshoping from '@assets/iconify/benefics/shopping-bag.svg';
import book from '@assets/iconify/benefics/book.svg';
import lightning from '@assets/iconify/benefics/lightning.svg';
import cardSearch from '@assets/iconify/benefics/card-search-black.svg';

export const beneficsData = {
  benefics: [
    { text: 'Bienestar', icon: heart },
    { text: 'Comercio', icon: bagshoping },
    { text: 'Educacion', icon: book },
    { text: 'Motivacion', icon: lightning }
  ],
  buttonIcon: cardSearch
};
