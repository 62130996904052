import './header.css';
import { icons, images } from '@data/home/header';
import Streaming from './Streaming';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  return (
    <main className='main'>
      <header
        className='card-header'
        style={{ backgroundImage: `url(${images.hospitalsBannerHeader})` }}
      >
        <section className='welcome-header'>
          <p className='welcome'>
            Bienvenido a nuestra Comunidad <b>VIP</b>
          </p>
          <h2 className='description'>
            Comparte esta experiencia de vida saludable y beneficios en nuestra
            comunidad del dolor
          </h2>
          <div className='image-container'>
            <ul className='image-list'>
              <li className='image-list-item'>
                <img
                  src={images.dolormed}
                  alt='ico'
                  className='h-[70px] w-[50px] lg:h-[82px] lg:w-[60px]'
                />
              </li>
              <li className='image-list-item'>
                <img
                  src={images.redesimat}
                  alt='ico'
                  className='h-[50px] w-[100px] lg:h-[54px] lg:w-[120px]'
                />
              </li>
              <li className='image-list-item'>
                <img
                  src={images.stefaniaarias}
                  alt='ico'
                  className='h-[60px] w-[100px] lg:h-[61px] lg:w-[120px]'
                />
              </li>
            </ul>
          </div>
          <div className='hidden-lg-block'>
            <span className='text-highlight'>
              Obtén tu <b>Tarjeta Plata</b> y pertenece a la comunidad VIP{' '}
              <b>Dolormed y RedesIMAT</b>
            </span>
          </div>
          <div className='contact'>
            <button
              className='message-btn'
              onClick={() => navigate('/contacto')}
            >
              <img src={icons.messageicon} />
              Contáctanos
            </button>
            <div className='social-media'>
              <a
                href='https://wa.me/573188216680'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img className='icon-social' src={icons.whatsappicon} />
              </a>
              <a
                href='https://www.facebook.com/dolormed1'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img className='icon-social' src={icons.facebookicon} />
              </a>
              <a
                href='https://www.instagram.com/dolormed/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img className='icon-social' src={icons.instagramicon} />
              </a>
            </div>
          </div>
        </section>
        <section className='img-content-card'>
          <img className='img-header' src={images.doctorsBannerHeader} />
        </section>
        <section className='steps-header'>
          <h3>
            Comunidad VIP
            <img className='vip-icon' src={icons.vipicon} />
          </h3>
          <ul className='my-12 lg:my-0'>
            <li className='item-step'>
              <img className='icon-step' src={icons.registerusericon} />
              <p>
                <b>Registrarte e inicia sesión</b> con tu cuenta persona y
                dirígete a tu menú principal
              </p>
            </li>
            <li className='item-step'>
              <img className='icon-step' src={icons.cardicon} />
              <p>
                Genera tu <b>Tarjeta Plata</b> y conéctate con la comunidad VIP
              </p>
            </li>
            <li className='item-step'>
              <img className='icon-step' src={icons.formicon} />
              <p>
                Llena el formulario de <b>afiliado</b> y asigna tus{' '}
                <b>5 beneficiarios</b>
              </p>
            </li>
          </ul>
          <div className='call-to-action'>
            <img src={images.cardvipimg} />
            <button
              className='call-to-action-btn'
              onClick={() => navigate('/autenticacion/registro')}
            >
              <img className='login-icon' src={icons.loginicon} />
              <p>Obtener gratis</p>
            </button>
          </div>
        </section>
      </header>
      <Streaming />
    </main>
  );
}
