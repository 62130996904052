import { Outlet } from 'react-router-dom';
import NavLocations from './navlocation/NavLocations';

export default function Location() {
  return (
    <>
      <div className='flex flex-wrap item-center justify-center w-full m-auto lg:w-[950px] gap-5'>
        <span className='bg-primaryText bg-opacity-10 py-1 px-2 rounded text-xl font-light md:text-xl'>
          Visitanos
        </span>
        <p className='text-center text-sm text-primaryText font-light mb-5 px-2 sm:px-0 lg:text-xl md:w-11/12'>
          Visítanos en cualquiera de nuestras sedes y experimenta la atención
          VIP que mereces.
          <br></br>
          <b className='text-bold'>
            ¡Consulta el mapa interactivo para ubicar fácilmente la clínica más
            cercana a ti y planear tu visita!
          </b>
        </p>
      </div>
      <NavLocations />
      <div className='flex flex-wrap  justify-center items-center'>
        <Outlet />
      </div>
    </>
  );
}
