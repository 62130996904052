import doctors from '@assets/images/doctors_dolormed.webp';

import dropdownicon from '@assets/iconify/about/dropdownicon.svg';
import star from '@assets/iconify/about/star-four-points-white.svg';
import fluent from '@assets/iconify/about/fluent-gift-card-white.svg';

import ambulanceBannerOur from '@assets/images/ambulance-banner-our.webp';
import doctorsBannerOur from '@assets/images/doctors-banner-our.webp';

export const aboutImg = {
  dropdownicon,
  star,
  fluent,
  doctors,
  ambulanceBannerOur,
  doctorsBannerOur
};
