import PageNotFound from '@components/404/PageNotFound';
import { Routes, Route } from 'react-router-dom';

export interface Props {
  children: JSX.Element[] | JSX.Element[];
}

export default function RoutesWithNotFound({ children }: Props) {
  return (
    <Routes>
      {children}

      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
}
