import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './map.css';
import IconLocation from './IconLocation';
import MapPoint from '@assets/iconify/map-point.svg';
import MapPointOutline from '@assets/iconify/map-point-outline.svg';
import StreetSign from '@assets/iconify/street-sign.svg';
import Planet from '@assets/iconify/planet.svg';
import Phone from '@assets/iconify/phone.svg';
import { Place } from '@interfaces/places';

const initialPosition: [number, number] = [
  4.085661326513574, -76.19110755134369
];
const googleMapsSkin = 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}';

interface placesProps {
  places: Place[];
}

interface MapUpdaterProps {
  geometry: [number, number];
}

function MapUpdater({ geometry }: MapUpdaterProps) {
  const map = useMap();
  map.setView(geometry, map.getZoom());
  return null;
}

export default function Map({ places }: placesProps) {
  const [geometry, setGeometry] = useState<[number, number]>(initialPosition);
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  useEffect(() => {
    if (places.length > 0) {
      const firstLocation = places[0].geometry;
      if (firstLocation.length === 2) {
        setGeometry(firstLocation as [number, number]);
      }
      setActiveIndex(0);
    }
  }, [places]);

  const changePlace = (newLocation: number[], index: number) => {
    if (newLocation.length === 2) {
      setGeometry(newLocation as [number, number]);
      setActiveIndex(index);
    }
  };

  return (
    <div className='flex flex-wrap md:flex-col lg:flex-row w-screen items-center justify-center'>
      <ul className='list-locations'>
        {places.map((place, index) => (
          <button
            key={index}
            className={`location-btn relative ${index === activeIndex ? 'active' : ''}`}
            onClick={() => changePlace(place.geometry, index)}
          >
            <h3>{place.name}</h3>
            <div className='items-start'>
              <img src={StreetSign} alt='icon' /> <span>{place.address}</span>
            </div>
            <div>
              <img src={Planet} alt='icon' /> {place.department}
            </div>
            {place.phoneNumbers.map(phone => (
              <div key={phone}>
                <img src={Phone} alt='icon' /> {phone}
              </div>
            ))}
            <div className='absolute right-[10px] top-[10px]'>
              <img
                src={index === activeIndex ? MapPoint : MapPointOutline}
                alt='icon'
                className='map-point'
              />
            </div>
          </button>
        ))}
      </ul>
      <div className='map-container'>
        <MapContainer center={geometry} zoom={20} key={geometry.toString()}>
          <TileLayer
            url={googleMapsSkin}
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {places.map((place, i) => (
            <Marker
              key={i}
              position={
                place.geometry.length === 2
                  ? (place.geometry as [number, number])
                  : [0, 0]
              }
              icon={IconLocation}
            />
          ))}
          <MapUpdater geometry={geometry} />
        </MapContainer>
      </div>
    </div>
  );
}
