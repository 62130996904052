import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthRoutes, PrivateRoutes } from '@interfaces/index';

const ADMIN = 'admin';
const USER = 'user';

export default function AuthGuard() {
  const jwt = localStorage.getItem('jwt');
  const role = localStorage.getItem('role');
  const location = useLocation();

  if (!jwt || !role) {
    return <Navigate replace to={`/${AuthRoutes.AUTH}`} />;
  }

  const isAccessingAdminRoute = location.pathname.startsWith(
    `/${PrivateRoutes.PRIVATE}/${PrivateRoutes.DASHBOARD}`
  );
  const isAccessingUserRoute = location.pathname.startsWith(
    `/${PrivateRoutes.PRIVATE}/${PrivateRoutes.USER}`
  );

  if (location.pathname === `/${PrivateRoutes.PRIVATE}` && role === ADMIN) {
    return <Navigate replace to={`/${PrivateRoutes.PRIVATE}/${PrivateRoutes.DASHBOARD}`} />;
  }

  if (location.pathname === `/${PrivateRoutes.PRIVATE}` && role === USER) {
    return <Navigate replace to={`/${PrivateRoutes.PRIVATE}/${PrivateRoutes.USER}`} />;
  }

  if (role === ADMIN) {
    if (isAccessingUserRoute) {
      return (
        <Navigate
          to={`/${PrivateRoutes.PRIVATE}/${PrivateRoutes.DASHBOARD}`}
          replace
        />
      );
    }
    return <Outlet />;
  }

  if (role === USER) {
    if (isAccessingAdminRoute) {
      return (
        <Navigate
          to={`/${PrivateRoutes.PRIVATE}/${PrivateRoutes.USER}`}
          replace
        />
      );
    }
    return <Outlet />;
  }

  return <Navigate replace to={`/${AuthRoutes.AUTH}`} />;
}
