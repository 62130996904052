import YouTubePlayer from 'react-player/youtube';
import './presentation.css';
import { presentationData, presentationVideo } from '@data/home/presentation';

export default function Presentation() {
  return (
    <>
      <section className='presentation-vip'>
        <div className='content-container'>
          <div className='content-title'>
            <h3 className='about-title'>
              ¿Quienes somos y quienes son nuestros{' '}
              <b className='text-bold'>Aliados</b>?
            </h3>
          </div>
          <div className='video-container'>
            <YouTubePlayer
              url={presentationVideo}
              controls
              width='100%'
              height='100%'
            />
          </div>
          <ul className='ally-list'>
            <h3 className='comunity-title'>
              Unidos por nuestra <b className='text-bold'>Comunidad VIP</b>
            </h3>
            {presentationData.map(ally => (
              <li key={ally.title} className='ally-item'>
                <div className='content-text'>
                  <h3>{ally.title}</h3>
                  <p>{ally.text}</p>
                </div>
                <div className='content-src'>
                  <img src={ally.logo} className='ally-logo' />
                  <div className='social-content'>
                    {ally.socials.map(social => (
                      <a
                        key={social.url}
                        href={social.url}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          key={social.url}
                          src={social.icon}
                          className='ally-social'
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
}
