import { LocationRoutes } from '@interfaces/routes';
import { NavLink } from 'react-router-dom';
import './nav.css';

export default function NavLocations() {
  return (
    <>
      <nav className='nav-locations'>
        <NavLink
          to={LocationRoutes.DOLORMED}
          className={({ isActive, isPending }) =>
            isPending ? 'pending' : isActive ? 'active' : ''
          }
        >
          Dolormed
        </NavLink>
        <NavLink
          className={({ isActive, isPending }) =>
            isPending ? 'pending' : isActive ? 'active' : ''
          }
          to={LocationRoutes.REDESIMAT}
        >
          RedesIMAT
        </NavLink>
        <NavLink
          className={({ isActive, isPending }) =>
            isPending ? 'pending' : isActive ? 'active' : ''
          }
          to={LocationRoutes.EstefaniaArias}
        >
          Estefanía Arias
        </NavLink>
        <NavLink
          className={({ isActive, isPending }) =>
            isPending ? 'pending' : isActive ? 'active' : ''
          }
          to={LocationRoutes.Optiglass}
        >
          Optiglass
        </NavLink>
      </nav>
    </>
  );
}
