import { registerBeneficiaryProps } from '@interfaces/register';

const addBeneficiaryService = async (
  body: registerBeneficiaryProps,
  jwt: string | null
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(
      `${import.meta.env.VITE_BACKEND_URL}/user/beneficiary`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(body),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resJson = await response.json();
    return resJson;
  } catch (error) {
    throw error;
  }
};

export default addBeneficiaryService;
