import { useContext, useEffect, useState } from 'react';
import './nav.css';
import { NavLink, Link } from 'react-router-dom';
import SesionContext from 'context/sesionContext';
import { SesionContextType, AuthRoutes, PublicRoutes } from '@interfaces/index';
import UserDropdown from './UserDropdown';
import DropIcon from '@assets/iconify/dashboard/arrow.svg';
import Card from '@assets/images/skinMembership.webp';
import PhoneIcon from '@assets/iconify/phone-solid.svg';
import MailIcon from '@assets/iconify/mail-filled.svg';
import AlertIcon from '@assets/iconify/bell-alert-solid.svg';

export default function Navbar() {
  const { isLoged } = useContext(SesionContext) as SesionContextType;
  const [fix, setFix] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const setFixed = () => {
    if (window.scrollY >= 20) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setFixed);
    return () => {
      window.removeEventListener('scroll', setFixed);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <nav
      className={`sticky top-0 bg-white z-50 shadow-md border-b border-gray-200 ${fix ? 'nav_fix' : ''}`}
    >
      <div
        className={`bg-primary h-fit py-1 sm:py-0 sm:h-[30px] expanded lg:expanded`}
      >
        <div className='flex justify-between items-center h-full mx-[2vw]'>
          <ul className='flex items-center list-none gap-6'>
            <li className='block xl:block'>
              <span className='text-[11px] text-white flex gap-1 items-center'>
                <img src={AlertIcon} alt='ico' className='w-[20px] h-[12px]' />{' '}
                Línea de emergencia <b>#414</b>
              </span>
            </li>
            <li className='hidden xl:block'>
              <span className='text-[11px] text-white flex gap-1 items-center'>
                <img src={PhoneIcon} alt='ico' className='w-[20px] h-[12px]' />{' '}
                +57 318 821 6680
              </span>
            </li>
            <li className='hidden xl:block'>
              <span className='text-[11px] text-white flex gap-1 items-center'>
                <img src={MailIcon} alt='ico' className='w-[12px] h-[12px]' />{' '}
                siau@dolormed.co
              </span>
            </li>
            <li className='hidden xl:block'>
              <span className='text-[11px] text-white flex gap-1 items-center'>
                <img src={MailIcon} alt='ico' className='w-[12px] h-[12px]' />{' '}
                vipcomunidad@gmail.com
              </span>
            </li>
          </ul>

          <ul className='flex items-center list-none gap-6'>
            <li className='block xl:block'>
              <Link
                to={`/${PublicRoutes.POLICIES}`}
                className='text-[11px] text-white flex gap-1 items-center hover:underline'
              >
                <img src={AlertIcon} alt='ico' className='w-[20px] h-[12px]' />{' '}
                Ver todas las politicas y terminos
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='flex flex-wrap items-center justify-between py-4 mx-[4vw] w-auto'>
        <NavLink
          to='/'
          className='flex items-center space-x-5 rtl:space-x-reverse'
        >
          <span className='flex gap-1 self-center bg-white text-primaryText text-xl md:text-xl'>
            Comunidad <b>VIP</b>
          </span>
        </NavLink>

        <div className='flex md:block md:order-2 space-x-5 md:space-x-0 rtl:space-x-reverse'>
          {!isLoged ? (
            <NavLink to={AuthRoutes.AUTH} className='button-nav'>
              Iniciar sesion
            </NavLink>
          ) : (
            <UserDropdown />
          )}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            type='button'
            className='inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
            aria-controls='navbar-sticky'
            aria-expanded={isMenuOpen}
          >
            <span className='sr-only'>Open main menu</span>
            <svg
              className='w-5 h-5'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 17 14'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M1 1h15M1 7h15M1 13h15'
              />
            </svg>
          </button>
        </div>
        <div
          className={`items-center justify-between ${isMenuOpen ? 'block' : 'hidden'} w-full md:flex md:w-auto md:order-1`}
          id='navbar-sticky'
        >
          <ul className='ul-nav'>
            <li className='item-nav cursor-pointer' onClick={toggleDropdown}>
              <span className='flex items-center gap-1'>
                Tarjetas{' '}
                <img
                  src={DropIcon}
                  alt=''
                  className={
                    dropdown
                      ? 'h-[13px] w-[13px] rotate-[90deg]'
                      : 'h-[13px] w-[13px] rotate-[-90deg]'
                  }
                />
              </span>
            </li>
            <li className='item-nav'>
              <NavLink to={`/${PublicRoutes.CONTACT}`}>Contáctanos</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={
          dropdown
            ? 'border-2 bg-white mt-3 absolute h-[160px] w-[400px] rounded-xl left-[50%] translate-x-[-50%]'
            : 'hidden'
        }
        onMouseLeave={toggleDropdown}
      >
        <ul className='flex items-center justify-around h-full'>
          <li className='h-full'>
            <div className='h-full flex flex-col items-start justify-center gap-2'>
              <h4 className='bg-[#ededed] py-1 px-2 rounded-md text-[#535353] font-semibold text-[14px]'>
                VIP Plata
              </h4>
              <img
                src={Card}
                alt='vip plata'
                className='h-[90px] w-[150px] bg-primary rounded-md'
              />
            </div>
          </li>
          <li className='h-full'>
            <div className='h-full flex flex-col items-start justify-center gap-2'>
              <h4 className='bg-[#fcefde] py-1 px-2 rounded-md text-[#e59e41] font-semibold text-[14px]'>
                VIP Oro
              </h4>
              <div className='flex h-[90px] w-[150px] rounded-md border-2 border-primary border-dashed bg-white text-center justify-center align-middle items-center'>
                Pronto lanzamiento
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
