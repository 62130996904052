import { useNavigate } from 'react-router-dom';
import { imgFooter } from '@data/footer';
import './footer.css';

export default function Footer() {
  const { vip, silvercard, login, facebook, instagram } = imgFooter;
  const navigate = useNavigate();

  return (
    <>
      <footer className='footer-bg'>
        <div className='bg-white h-[50px] w-full rounded-b-[50px]'></div>
        <div className='footer-container'>
          <div className='footer-content'>
            <div className='footer-left'>
              <div className='flex items-center gap-2'>
                <p className='footer-title'>Comunidad Plata</p>
                <img src={vip} className='footer-logo' alt='FlowBite Logo' />
              </div>
              <div className='call-to-action-btns'>
                <img src={silvercard} className='silvercard-image' />
                <button
                  className='btn btn-try'
                  onClick={() => navigate('/autenticacion/registro')}
                >
                  <img src={login} className='login-image' />
                  <p className='btn-text'>Obtener gratis</p>
                </button>
              </div>
            </div>
            <div className='footer-links'>
              <div className='link-section'>
                <h2 className='link-title'>Soporte</h2>
                <ul className='link-list'>
                  <li className='link-item'>
                    <a
                      href='https://wa.me/3188216680'
                      className='link'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Soporte al cliente
                    </a>
                  </li>
                  <li className='link-item'>
                    <a
                      href='https://wa.me/573008113256'
                      className='link'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Soporte tecnico
                    </a>
                  </li>
                </ul>
              </div>
              <div className='link-section'>
                <h2 className='link-title'>Comunidad</h2>
                <ul className='link-list'>
                  <li className='link-item'>
                    <a href='/contacto' className='link'>
                      Contáctanos
                    </a>
                  </li>
                </ul>
              </div>
              <div className='link-section'>
                <h2 className='link-title'>Legal</h2>
                <ul className='link-list'>
                  <li className='link-item'>
                    <a href='/politicas' className='link'>
                      Politicas y terminos de la Comunidad VIP
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='footer-bottom'>
            <span className='footer-copy'>
              © 2024{' '}
              <a href='https://flowbite.com/' className='footer-link'>
                Derechos reservados™
              </a>
              . Dolormed &amp; RedesIMAT.
            </span>
            <div className='footer-social'>
              <a
                href='https://www.facebook.com/dolormed1'
                className='social-link ms-5'
              >
                <img src={facebook} className='social-icon' />
                <span className='sr-only'>Facebook</span>
              </a>
              <a
                className='social-link ms-5'
                href='https://www.instagram.com/dolormed/'
              >
                <img src={instagram} className='social-icon' />
                <span className='sr-only'>Instagram</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
