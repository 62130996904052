import { useCallback, useContext } from 'react';
import { stateValidate } from 'enum/state';
import userService from 'service/user.service';
import { useDispatch } from 'react-redux';
import {
  createUser,
  resetUser,
  updateAddBeneficiary
} from '@store/states/userSlice';
import { AuthContext, SesionContext } from 'context/index';
import {
  SesionContextType,
  PublicRoutes,
  registerProps,
  AuthContextType
} from '@interfaces/index';
import { useNavigate } from 'react-router-dom';
import { clearLocalStorageUser } from '@utils/persistAndClearLocalStorage';
import addBeneficiaryService from 'service/addBeneficiary.service';
import { valFails } from 'enum/valFails';

export default function useInfoUser() {
  const jwt = localStorage.getItem('jwt');

  const { setIsOpen } = useContext(AuthContext) as AuthContextType;
  const { setIsLoged } = useContext(SesionContext) as SesionContextType;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGetUser = useCallback(async () => {
    try {
      const response = await userService(jwt);

      if (response.state === stateValidate.OK) {
        dispatch(createUser(response.data[0]));
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const handleCreateBeneficiary = async (body: registerProps) => {
    try {
      const error: string[] = [];
      const { password, ...bodyWithoutPassword } = { ...body };

      const response = await addBeneficiaryService(bodyWithoutPassword, jwt);

      if (response.state === stateValidate.OK) {
        setIsOpen(true);

        const newBeneficiary = {
          firstNames: body.firstNames,
          lastNames: body.lastNames,
          dni: body.dni
        };
        dispatch(updateAddBeneficiary(newBeneficiary));
      }
      if (response.state === stateValidate.ERRROR) {
        if (response.error.code === 101) {
          response.error.valFail === valFails.DNI && error.push('La cedula/cc ya ha sido registrada');
          response.error.valFail === valFails.EMAIL && error.push('El correo ya ha sido registrado');
          response.error.valFail === valFails.PHONENUMBER && error.push('El telefono ya ha sido registrado');
        }
        if (response.error.code === 103) {
          response.error.valFail === valFails.PHONENUMBER && error.push('El telefono supera el numero de caracteres permitidos de 10');
          response.error.valFail === valFails.DNI && error.push('La cedula/cc supera el numero de caracteres permitidos de 10');
          response.error.valFail === valFails.EMAIL && error.push('El correo supera el numero de caracteres permitidos de 100');
          response.error.valFail === valFails.LASTNAME && error.push('El apellido debe ser mayor a 3 caracteres');
        }
        if (response.error.code === 107) {
          error.push('Ya tienes este beneficiario agregado por cedula');
        }
      }

      return error;
    } catch (error) {
      console.log(error);
      return ['Error en el servidor, porfavor contactar con soporte al cliente'];
    }
  };

  const handleLogout = (shouldRedirect: boolean = true) => {
    dispatch(resetUser());
    clearLocalStorageUser();
    setIsLoged(false);

    if (shouldRedirect) {
      navigate(PublicRoutes.HOME);
    }
  };

  return {
    handleGetUser,
    handleLogout,
    handleCreateBeneficiary
  };
}
