interface Props {
  height?: string;
}

export default function Loading({ height = '1000px' }: Props) {
  return (
    <div
      className='flex flex-row gap-2 w-screen justify-center items-center'
      style={{ height }}
    >
      <div className='w-4 h-4 rounded-full bg-primary animate-bounce'></div>
      <div className='w-4 h-4 rounded-full bg-primary animate-bounce [animation-delay:-.3s]'></div>
      <div className='w-4 h-4 rounded-full bg-primary animate-bounce [animation-delay:-.5s]'></div>
    </div>
  );
}
