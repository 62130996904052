export const locationsData = {
  DolormedPlaces: [
    {
      name: 'Dolormed Tulua',
      address: 'Cra. 33ª # 24 – 17 Barrio Alvernia',
      department: 'Valle del Cauca',
      phoneNumbers: ['+573188216680', '6023980033 Ext 1'],
      geometry: [4.085661326513574, -76.19110755134369]
    },
    {
      name: 'Dolormed Zarzal',
      address: 'Calle 5 # 11 - 70 Barrio Inmaculada',
      department: 'Valle del Cauca',
      phoneNumbers: ['+573212378585', '6023980033 - Ext 5'],
      geometry: [4.391551, -76.075442]
    }
  ],
  RedesImatPlaces: [
    {
      name: 'RedesIMAT Buga',
      address: 'Calle 2 # 14 – 31 Barrio Centro',
      department: 'Valle del Cauca',
      phoneNumbers: ['+573185851777', '6023981969'],
      geometry: [3.8960448728924875, -76.30300761678392]
    }
  ],
  OptiglassPlaces: [
    {
      name: 'Optiglass Tuluá',
      address: 'Carrera 37 # 25 - 79 Local B102',
      department: 'Valle del Cauca',
      phoneNumbers: ['+573009796193'],
      geometry: [4.083717608669661, -76.18839731678393]
    }
  ],
  EstefaniaAriasPlace: [
    {
      name: 'Estefanía Arias Tuluá',
      address: 'Carrera 40 # 35 - 13 Barrio el Retiro',
      department: 'Valle del Cauca',
      phoneNumbers: ['+573023594589'],
      geometry: [4.075278418127022, -76.18973796096564]
    }
  ]
};
