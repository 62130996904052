import { UserModel } from '@interfaces/user';

export const persistLocalStorageUser = (userInfo: UserModel) => {
  localStorage.setItem('user', JSON.stringify({ ...userInfo }));
};

export const clearLocalStorageUser = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('jwt');
  localStorage.removeItem('role');
};
