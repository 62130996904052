import './call-to-action.css';

import bg_card from 'assets/images/bg_cards.webp';
import vip from '@assets/iconify/vipicon-violet.svg';
import star from '@assets/iconify/about/mdi--star-four-points-circle-outline.svg';
import cards from '@assets/iconify/about/fluent--gift-card-multiple-20-regular.svg';
import silvercard from '@assets/images/skinMembership.webp';
import login from '@assets/iconify/login-gray.svg';
import { useNavigate } from 'react-router-dom';

export default function CallToAction() {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${bg_card})`
        }}
        className='call-to-action-bg bg-center sm:bg-none'
      >
        <section className='call-to-action-section'>
          <div className='call-to-action-content'>
            <span className='bg-[rgba(8,25,73,0.07)] py-1 px-2 rounded text-xl'>
              Tarjeta <b className='text-bold'>Plata</b>
            </span>
            <p>
              Disfruta de beneficios exclusivos con nuestras{' '}
              <b>Tarjeta Plata</b>. Desde <b>Plata</b> hasta la premium{' '}
              <b>Oro</b>, cada nivel te ofrece mayores descuentos, acceso a
              servicios especiales y la oportunidad de canjear tus puntos por
              premios aún más codiciados.
            </p>
            {/* <button className='btn'>
              <img src={mingcute} />
              Ver todas las tarjeta
            </button> */}
          </div>
          <div className='call-to-action-card'>
            <header className='header'>
              <h2>
                Inicia en la <b>Comunidad VIP</b> con la Tarjeta Plata
                totalmente gratis
              </h2>
              <img className='w-20 h-20' src={vip} />
            </header>
            <ul className='list-offers'>
              <li className='item-list'>
                <img src={star} />
                <p>
                  Por el inicio de tu <b>tarjeta plata</b> obtendrás{' '}
                  <b>beneficios gratis</b>
                </p>
              </li>
              <li className='item-list'>
                <img src={cards} />
                <p>
                  Obtén beneficios de <b>descuento</b> desde el <b>35%</b> en
                  los servicios de salud de nuestros aliados
                </p>
              </li>
            </ul>
            <div className='call-to-action-btns'>
              <img src={silvercard} />
              <button
                className='btn bg-[#fafafa] ml-2 rounded-lg'
                onClick={() => navigate('/autenticacion/registro')}
              >
                <img src={login} />
                <p>Obtener gratis</p>
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
