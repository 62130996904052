import facebookicon from '@assets/iconify/facebook.svg';
import instagramicon from '@assets/iconify/instagram.svg';
import whatsappicon from '@assets/iconify/whatsapp.svg';
import whatsappiconHover from '@assets/iconify/whatsapphover.svg';
import dropdownicon from '@assets/iconify/dropdownicon.svg';
import messageicon from '@assets/iconify/mail-filled.svg';
import registerusericon from '@assets/iconify/registeruser.svg';
import formicon from '@assets/iconify/form.svg';
import cardicon from '@assets/iconify/vipcard.svg';
import loginicon from '@assets/iconify/login.svg';
import vipicon from '@assets/iconify/vipicon.svg';

import hospitalsBannerHeader from '@assets/images/hospitals-banner-header.webp';
import doctorsBannerHeader from '@assets/images/doctors-banner-header.webp';

import familyimg from '@assets/images/family.png';
import cardvipimg from '@assets/images/skinMembership.webp';

import dolormed from '@assets/images/dolormed.webp';
import stefaniaarias from '@assets/images/stefaniaarias.webp';
import redesimat from '@assets/images/redesimat.webp';

export const icons = {
  facebookicon,
  instagramicon,
  whatsappicon,
  whatsappiconHover,
  dropdownicon,
  vipicon,
  messageicon,
  cardicon,
  formicon,
  registerusericon,
  loginicon
};
export const images = {
  hospitalsBannerHeader,
  doctorsBannerHeader,
  familyimg,
  cardvipimg,
  dolormed,
  redesimat,
  stefaniaarias
};

export const dataStreaming = [
  { title: 'Afiliados', ammount: 150 },
  { title: 'Beneficiarios', ammount: 2000 },
  { title: 'Aliados', ammount: 1100 },
  { title: 'Ahorro VIP', ammount: 42, chart: '%' }
];
