import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { Provider } from 'react-redux';
import Appstore from './store/Appstore';
import { SesionContextProvider } from 'context';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={Appstore}>
      <SesionContextProvider>
        <App />
      </SesionContextProvider>
    </Provider>
  </React.StrictMode>
);
