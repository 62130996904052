import Footer from '@components/footer/footer';
import Navbar from '@components/nav/Navbar';
import { Outlet } from 'react-router-dom';
import { AuthContextProvider } from 'context';

export default function LayoutWithNavAndFoot() {
  return (
    <>
      <AuthContextProvider>
        <Navbar />
        <Outlet />
        <Footer />
      </AuthContextProvider>
    </>
  );
}
