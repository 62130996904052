import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '@store/Appstore';
import { UserModel } from '@interfaces/user';
import useInfoUser from '@hooks/useInfoUser';
import { Link } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from '@interfaces/routes';
import homeHover from '@assets/iconify/home-hover.svg';
import membershipHover from '@assets/iconify/userDropdown.svg';
import dashboardHover from '@assets/iconify/dashboardHover.svg';
import './userdropdown.css';
import formatName from 'adapter/formatName';

const UserDropdown: React.FC = () => {
  const user = useSelector((store: AppStore): UserModel => store.user);
  const { handleLogout } = useInfoUser();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const role = localStorage.getItem('role');
  const fullName =
    role === 'admin'
      ? 'Administrador'
      : formatName(user.firstNames.split(' ')[0], user.lastNames.split(' ')[0]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className='dropdown-container'>
      <button
        id='dropdownInformationButton'
        onClick={toggleDropdown}
        className='dropdown-button'
        type='button'
      >
        {fullName}
        <svg
          className='dropdown-icon'
          aria-hidden='true'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 10 6'
        >
          <path
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='m1 1 4 4 4-4'
          />
        </svg>
      </button>

      {isOpen && (
        <div id='dropdownInformation' className='dropdown-content'>
          <div className='dropdown-header'>
            <div className='dropdown-email'>{user.email}</div>
          </div>
          <ul
            className='dropdown-menu'
            aria-labelledby='dropdownInformationButton'
          >
            <li>
              <Link className='dropdown-item' to={PublicRoutes.HOME}>
                <img className='mr-2' src={homeHover} />
                Inicio
              </Link>
            </li>
            <li>
              {role === 'admin' ? (
                <Link className='dropdown-item' to={`/${PrivateRoutes.PRIVATE}/${PrivateRoutes.DASHBOARD}`}>
                  <img className='mr-2' src={dashboardHover} />
                  Dashboard
                </Link>
              ) : (
                <Link
                  className='dropdown-item'
                  to={`/${PrivateRoutes.PRIVATE}/${PrivateRoutes.USER}`}
                >
                  <img className='mr-2' src={membershipHover} />
                  Mis Beneficios
                </Link>
              )}
            </li>
          </ul>
          <div className='dropdown-footer'>
            <button
              onClick={() => handleLogout(true)}
              className='dropdown-logout'
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
