import { createContext, useState } from 'react';
import { SesionContextType, ChildrenProps } from '@interfaces/index';

const SesionContext = createContext<SesionContextType | undefined>(undefined);

export function SesionContextProvider({ children }: ChildrenProps) {
  const user = localStorage.getItem('user');
  const jwt = localStorage.getItem('jwt');
  const role = localStorage.getItem('role');

  const loged = user && jwt && role ? true : false;
  const [isLoged, setIsLoged] = useState<boolean>(loged);
  return (
    <SesionContext.Provider value={{ isLoged, setIsLoged }}>
      {children}
    </SesionContext.Provider>
  );
}

export default SesionContext;
