import { createSlice } from '@reduxjs/toolkit';
import { EmptyUserState } from '@interfaces/user';
import {
  persistLocalStorageUser,
  clearLocalStorageUser
} from '@utils/persistAndClearLocalStorage';

export const userSlice = createSlice({
  name: 'user',
  initialState: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : EmptyUserState,
  reducers: {
    createUser: (_state, action) => {
      persistLocalStorageUser(action.payload);
      return action.payload;
    },
    updateUser: (state, action) => {
      const result = { ...state, ...action.payload };
      persistLocalStorageUser(result);
      return result;
    },
    updateAddBeneficiary: (state, action) => {
      state.card.beneficiaries.push(action.payload);
      state.membership.beneficiaries += 1;
      console.log(state);
      persistLocalStorageUser(state);
      return state;
    },
    resetUser: () => {
      clearLocalStorageUser();
      return EmptyUserState;
    }
  }
});

export const { createUser, updateUser, updateAddBeneficiary, resetUser } =
  userSlice.actions;

export default userSlice.reducer;
