import icon from '@assets/iconify/location.svg';
import { Icon } from 'leaflet';

const IconLocation = new Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconSize: [35, 35],
  className: 'leaflet-venue-icon'
});

export default IconLocation;
