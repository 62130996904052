import { DashboardRoutes } from '@interfaces/routes';
import './sidebar.css';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AdminPanelIcon from '@assets/iconify/dashboard/admin-panel-settings-outline-rounded.svg';
import HomeBoldIcon from '@assets/iconify/dashboard/home-bold.svg';
import HomeLineIcon from '@assets/iconify/dashboard/home-line.svg';
import Logout from '@assets/iconify/dashboard/round-logout.svg';
import DropDownIcon from '@assets/iconify/dashboard/arrow.svg';
import UsersLineIcon from '@assets/iconify/dashboard/users.svg';
import UsersFillIcon from '@assets/iconify/dashboard/users-fill.svg';
import TagsLineIcon from '@assets/iconify/dashboard/tag.svg';
import TagsFillIcon from '@assets/iconify/dashboard/tag-fill.svg';
import GiftCardIcon from '@assets/iconify/dashboard/gift-card-line.svg';
import GiftCardFillIcon from '@assets/iconify/dashboard/gift-card-fill-white.svg';
import VipLineIcon from '@assets/iconify/dashboard/vip-2-line.svg';
import VipFillIcon from '@assets/iconify/dashboard/vip-2-fill.svg';
import WhatsappFillIcon from '@assets/iconify/dashboard/whatsapp.svg';

import { useInfoUser } from 'hooks/index';

export default function Sidebar() {
  const [dropdown, setDropdown] = useState<boolean>(false);
  const { handleLogout } = useInfoUser();

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className='sidebar h-full'>
      <div className='head'>
        <div className='auth-icon'>
          <span>
            <img src={AdminPanelIcon} alt='' className='w-[30px] h-[30px]' />
          </span>
        </div>
        <div className='user-name'>Administrador</div>
        <div
          className='drop-box flex items-center justify-center'
          onClick={toggleDropdown}
        >
          <span className=''>
            <img
              src={DropDownIcon}
              alt=''
              className={
                dropdown
                  ? 'rotate-[90deg] h-[14px] w-[14px]'
                  : 'rotate-[270deg] h-[14px] w-[14px]'
              }
            />
          </span>
        </div>
      </div>
      <div className='body'>
        <div className={dropdown ? 'dropdown' : 'hidden'}>
          <div className='h-[35px]'>
            <NavLink
              to={'/'}
              className='flex items-center text-[14px] cursor-pointer gap-1 hover:underline'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span>
                <img src={isHovered ? HomeBoldIcon : HomeLineIcon} alt='Home' />
              </span>
              Ir al inicio
            </NavLink>
          </div>
          <div
            className='h-[35px] border-2 border-[#fb714a] text-[#fb714a] text-[13px] rounded flex justify-center items-center cursor-pointer gap-1 hover:bg-[#fec9ba]'
            onClick={() => handleLogout(true)}
          >
            <span>
              <img src={Logout} alt='Home' />
            </span>{' '}
            Cerrar sesión
          </div>
        </div>
        <div className='content'>
          <div>
            <h5 className='text-[10px] text-[#535353] opacity-70'>
              ADMINISTRACIÓN
            </h5>
          </div>
          <div className='items'>
            <ul className='list-items'>
              <li className='item'>
                <NavLink
                  to={DashboardRoutes.USERS}
                  className={({ isActive }) => (isActive ? 'selected' : '')}
                >
                  <span className='img-icon'>
                    <img src={UsersLineIcon} alt='' className='icon-line' />
                    <img src={UsersFillIcon} alt='' className='icon-fill' />
                  </span>{' '}
                  Usuarios
                </NavLink>
              </li>
              <li className='item'>
                <NavLink
                  to={DashboardRoutes.TAGS}
                  className={({ isActive }) => (isActive ? 'selected' : '')}
                >
                  <span className='img-icon'>
                    <img src={TagsLineIcon} alt='' className='icon-line' />
                    <img src={TagsFillIcon} alt='' className='icon-fill' />
                  </span>{' '}
                  Etiquetas
                </NavLink>
              </li>
              <li className='item'>
                <NavLink
                  to={DashboardRoutes.BENEFITS}
                  className={({ isActive }) => (isActive ? 'selected' : '')}
                >
                  <span className='img-icon'>
                    <img src={GiftCardIcon} alt='' className='icon-line' />
                    <img src={GiftCardFillIcon} alt='' className='icon-fill' />
                  </span>{' '}
                  Beneficios
                </NavLink>
              </li>
              <li className='item'>
                <NavLink
                  to={DashboardRoutes.MEMBERSHIPS}
                  className={({ isActive }) => (isActive ? 'selected' : '')}
                >
                  <span className='img-icon'>
                    <img src={VipLineIcon} alt='' className='icon-line' />
                    <img src={VipFillIcon} alt='' className='icon-fill' />
                  </span>{' '}
                  Tarjetas
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className='footer'>
          <div className='support'>
            <a
              href='https://wa.me/573008113256'
              target='_blank'
              rel='noopener noreferrer'
              className='flex items-center gap-2 text-[14px] flex-wrap'
            >
              <span className='w-[22px] h-[22px]'>
                <img src={WhatsappFillIcon} alt='' />
              </span>
              Contactar a soporte
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
