import './about.css';
import { aboutImg } from '@data/home/about';

export default function About() {
  return (
    <>
      <section className='about-vip'>
        <div
          className='content-container'
          style={{ backgroundImage: `url(${aboutImg.ambulanceBannerOur})` }}
        >
          <div className='content-title'>
            <h3 className='about-title'>
              ¿Qué es la Comunidad <b className='text-bold'>VIP</b> y como
              funciona?
            </h3>
          </div>
          <img className='about-img' src={aboutImg.doctorsBannerOur} />
          <ul className='about-list'>
            <li className='list-item'>
              <img className='about-icon' src={aboutImg.dropdownicon} />
              <p className='about-text'>
                <b className='text-bold'>La comunidad VIP</b> es nuestro
                exclusivo programa de lealtad que premia tu fidelidad. Al
                unirte, obtendrás una <b className='text-bold'>Tarjeta Plata</b>{' '}
                que te dará acceso a increíbles beneficios, como descuentos de
                hasta el <b className='text-bold'>35%</b> en todos nuestros
                servicios médicos.
              </p>
            </li>
            <li className='list-item'>
              <img className='about-icon' src={aboutImg.star} />
              <p className='about-text'>
                Cada vez que utilices nuestros servicios médicos y presentes tu{' '}
                <b className='text-bold'>Tarjeta Plata</b>, acumularás puntos.
                Estos puntos se irán sumando en tu cuenta de la Plataforma
                Aliados y podrás canjearlos por fantásticos premios.{' '}
                <b className='text-bold'>¡Entre más servicios, más puntos!</b>
              </p>
            </li>
            <li className='list-item'>
              <img className='about-icon' src={aboutImg.fluent} />
              <p className='about-text'>
                En nuestra <b className='text-bold'>Plataforma Aliados</b>,
                encontrarás un catálogo con una variedad de premios
                emocionantes. Desde artículos para el hogar hasta experiencias
                únicas, podrás{' '}
                <b className='text-bold'>canjear tus puntos acumulados</b> por
                lo que más te guste.{' '}
                <b className='text-bold'>
                  ¡Además, participarás en sorteos mensuales con grandes
                  premios!
                </b>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
