export const PublicRoutes = {
  HOME: '/',
  CONTACT: 'contacto',
  POLICIES: 'politicas'
};

export const AuthRoutes = {
  LOGIN: '',
  AUTH: 'autenticacion',
  REGISTER: 'registro'
};

export const LocationRoutes = {
  DOLORMED: '/',
  REDESIMAT: 'redesimat',
  EstefaniaArias: 'estefanía-arias',
  Optiglass: 'optiglass'
};

export const PrivateRoutes = {
  PRIVATE: 'privado',
  DASHBOARD: 'administrador',
  USER: 'usuario'
};

export const DashboardRoutes = {
  USERS: 'usuarios',
  BENEFITS: 'beneficios',
  MEMBERSHIPS: 'tarjetas',
  TAGS: 'etiquetas'
};
