import {
  Header,
  About,
  Presentation,
  Benefics,
  CallToAction,
  Location
} from '@components/home';
import { FloatingButton } from '@components/buttonFloat';

export default function Home() {
  return (
    <>
      <Header />
      <Benefics />
      <About />
      <CallToAction />
      <Presentation />
      <Location />
      <FloatingButton />
    </>
  );
}
