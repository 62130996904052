export interface BeneficiariesModel {
  firstNames: string;
  lastNames: string;
  dni: string;
}

export interface CardModel {
  id: string;
  points: number;
  urlBackground: string;
  beneficiaries: BeneficiariesModel[];
}

export interface MemberShipModel {
  id: string;
  type: string;
  idType: number;
  benefitsRedeemed: number;
  beneficiaries: number;
}

export interface UserModel {
  id: string;
  dni: string;
  email: string;
  firstNames: string;
  lastNames: string;
  phoneNumber: string;
  dateBorn: string;
  password: string;
  occupationalSector: string;
  gender: string;
  educationLevel: string;
  residenceAddress: string;
  socioEconomicStatus: string;
  beneficiaryMembership: string;
  createdAt: string;
  city: string;
  state: number | undefined;
  card: CardModel;
  membership: MemberShipModel;
}

export const EmptyUserState: UserModel = {
  id: '',
  dni: '',
  email: '',
  firstNames: '',
  lastNames: '',
  phoneNumber: '',
  dateBorn: '',
  password: '',
  occupationalSector: '',
  gender: '',
  educationLevel: '',
  residenceAddress: '',
  socioEconomicStatus: '',
  beneficiaryMembership: '',
  createdAt: '',
  city: '',
  state: 0,
  card: {
    id: '',
    points: 0,
    urlBackground: '',
    beneficiaries: []
  },
  membership: {
    id: '',
    type: '',
    idType: 0,
    benefitsRedeemed: 0,
    beneficiaries: 0
  }
};

export interface Beneficiary {
  dni: string;
  firstNames: string;
  lastNames: string;
}

export interface Card {
  id: string;
  points: number;
  state: number;
  urlBackground: string;
  usedAt: string;
  expiredPointsAt: string;
  beneficiaries: Beneficiary[];
}

export interface MembershipUser {
  id: string;
  type: string;
  idType: number;
  benefitsRedeemed: number;
  beneficiaries: number;
  createdAt: Date;
  expiredAt: Date;
}

export interface User {
  id: string;
  email: string;
  firstNames: string;
  lastNames: string;
  occupationalSector: number;
  dni: string;
  dateBorn: string;
  password: string;
  gender: number;
  socioEconomicStatus: number;
  residenceAddress: string;
  beneficiaryMembership: string | null;
  educationLevel: number;
  phoneNumber: string;
  city: string;
  state: number;
  createdAt: string;
  card: Card;
  membership: MembershipUser;
}

export interface UsersTable {
  users: User[];
  nextPage: number | null;
  prevPage: number | null;
  totalPages: number;
}
