import bg_404 from '@assets/images/hospitals-banner-header.webp';
import { NavLink } from 'react-router-dom';
import { PublicRoutes } from '@interfaces/routes';

export default function PageNotFound() {
  return (
    <div
      style={{
        backgroundImage: `url(${bg_404})`,
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover'
      }}
      className='bg-primary w-screen h-screen flex flex-wrap justify-center align-middle items-center'
    >
      <section className='flex flex-wrap align-middle justify-center'>
        <h2 className='text-[100px] text-white font-bold w-full text-center'>
          404
        </h2>
        <p className='text-xl text-white font-bold w-full text-center mb-1'>
          Oops! Tuvimos un problema encontrando esta página
        </p>
        <p className='text-sl text-white w-full text-center mb-4'>
          Vuelve a la comunidad desde el siguiente botón
        </p>
        <NavLink
          to={PublicRoutes.HOME}
          replace
          className='text-white border-secondary border-2 py-2 px-4 rounded-full text-center hover:bg-secondary hover:text-primary transition duration-150 ease-in'
        >
          Ir al inicio
        </NavLink>
      </section>
    </div>
  );
}
