import './floatingbutton.css';

import { icons } from '@data/home/header';

export default function FloatingButton() {
  const whatsappURL = 'https://wa.me/573188216680';

  return (
    <a href={whatsappURL} target='_blank' className='floating-button'>
      Chatea con nosotros
      <img className='w-6 h-6' src={icons.whatsappicon} />
    </a>
  );
}
