import { configureStore } from '@reduxjs/toolkit';
import { UserModel } from '@interfaces/user';
import userSlice from './states/userSlice';

export interface AppStore {
  user: UserModel;
}

export default configureStore<AppStore>({
  reducer: {
    user: userSlice
  }
});
