import { lazy, Suspense, useContext, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import {
  PublicRoutes,
  LocationRoutes,
  AuthRoutes,
  PrivateRoutes
} from '@interfaces/routes';
import { Home } from './pages';
import RoutesWithNotFound from '@utils/RoutesWithNotFound';
const LazyDolormedLoc = lazy(
  () => import('@components/home/location/DolormedLoc')
);
import LazyEstefaniaAriasLoc from '@components/home/location/EstefaniaAriasLoc';
import LazyOptiglassLoc from '@components/home/location/OptiglassLoc';
import LazyRedesImatLoc from '@components/home/location/RedesImatLoc';
const LazyContact = lazy(() => import('@pages/Public/Contact'));
const LazyAuth = lazy(() => import('@pages/Public/Auth'));
const LazyLogin = lazy(() => import('components/auth/login/Login'));
const LazyRegister = lazy(() => import('@components/auth/register/Register'));
const LazyPolicies = lazy(() => import('@pages/Public/Policies'));
import LayoutWithNavAndFoot from '@utils/LayoutWithNavAndFoot';

const LazyPrivate = lazy(() => import('@pages/Private/Private'));
import AuthGuard from 'guards/AuthGuard';
import Loading from '@components/shared/loader/Loading';
import { SesionContext } from 'context';
import { SesionContextType } from './interfaces';

function App() {
  const { setIsLoged } = useContext(SesionContext) as SesionContextType;

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    const role = localStorage.getItem('role');

    if (jwt && role) {
      setIsLoged(true);
    } else {
      setIsLoged(false);
    }
  }, [setIsLoged]);
  
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <RoutesWithNotFound>
          <Route element={<LayoutWithNavAndFoot />}>
            <Route path={PublicRoutes.HOME} element={<Home />}>
              <Route
                path={LocationRoutes.DOLORMED}
                element={<LazyDolormedLoc />}
              />
              <Route
                path={LocationRoutes.REDESIMAT}
                element={<LazyRedesImatLoc />}
              />
              <Route
                path={LocationRoutes.EstefaniaArias}
                element={<LazyEstefaniaAriasLoc />}
              />
              <Route
                path={LocationRoutes.Optiglass}
                element={<LazyOptiglassLoc />}
              />
            </Route>
            <Route
              path={PublicRoutes.CONTACT}
              element={<LazyContact />}
            ></Route>
            <Route
              path={PublicRoutes.POLICIES}
              element={<LazyPolicies />}
            ></Route>
          </Route>
          <Route path={AuthRoutes.AUTH} element={<LazyAuth />}>
            <Route path={AuthRoutes.LOGIN} element={<LazyLogin />} />
            <Route path={AuthRoutes.REGISTER} element={<LazyRegister />} />
          </Route>
          <Route element={<AuthGuard />}>
            <Route
              path={`${PrivateRoutes.PRIVATE}/*`}
              element={<LazyPrivate />}
            />
          </Route>
        </RoutesWithNotFound>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
