import facebook from '@assets/iconify/facebook.svg';
import instagram from '@assets/iconify/instagram.svg';

import dolormed from '@assets/images/dolormed.webp';
import redesimat from '@assets/images/redesimat.webp';
import estefania from '@assets/images/stefaniaarias.webp';
import optiglass from '@assets/images/optiglass.webp';

export const presentationVideo = 'https://youtu.be/9pDbEgtwcl4';

export const presentationData = [
  {
    title: 'Dolormed',
    text: 'Clínica traumas ubicada en el Tuluá Valle del Cauca enfocada al examinar,tratar y rehabilitar de patologías de orden osteomusculares, tu bienestar en nuestras manos',
    logo: dolormed,
    socials: [
      { icon: facebook, url: 'https://www.facebook.com/dolormed1' },
      { icon: instagram, url: 'https://www.instagram.com/dolormed/' }
    ]
  },
  {
    title: 'RedesIMAT',
    text: 'RedesIMAT es una de las mejores clínicas de fracturas ubicada en la ciudad señora Guadalajara de Buga, actualmente cuenta con más de 10 años de experiencia en los servicios de salud, enfocados en el bienestar de todos nuestros pacientes y usuarios. ¡Tu salud… lo más importante!.',
    logo: redesimat,
    socials: [
      { icon: facebook, url: 'https://www.facebook.com/redesimat' },
      { icon: instagram, url: 'https://www.instagram.com/redesimat/' }
    ]
  },
  {
    title: 'EstefaniaArias',
    text: 'Especialista en dermatología antienvejecimiento, Dr Estefanía Arias te brindará todo su conocimiento y experiencia para que puedas lucir una piel sana y saludable.',
    logo: estefania,
    socials: [
      { icon: facebook, url: 'https://www.facebook.com/dra.estefania.arias' },
      { icon: instagram, url: 'https://www.instagram.com/dra.estefania.arias/' }
    ]
  },
  {
    title: 'OptiGlass',
    text: 'Servicio de optometría ubicada en el corazón del Valle, agenda tu cita hoy mismo.',
    logo: optiglass,
    socials: [
      { icon: facebook, url: 'https://www.facebook.com/optiglasstulua' },
      { icon: instagram, url: 'https://www.instagram.com/optiglasstulua/' }
    ]
  }
];
