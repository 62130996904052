import Map from '@components/map/Map';
import { locationsData } from '@data/home/locations';

export default function OptiglassLoc() {
  const { OptiglassPlaces } = locationsData;
  return (
    <>
      <Map places={OptiglassPlaces} />
    </>
  );
}
