import Map from '@components/map/Map';
import { locationsData } from '@data/home/locations';

export default function EstefaniaAriasLoc() {
  const { EstefaniaAriasPlace } = locationsData;
  return (
    <>
      <Map places={EstefaniaAriasPlace} />
    </>
  );
}
