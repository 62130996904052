import { beneficsData } from '@data/home/benefics';
import './benefics.css';
import { useNavigate } from 'react-router-dom';

export default function Benefics() {
  const { buttonIcon } = beneficsData;
  const navigate = useNavigate();

  return (
    <section className='benefics'>
      <div className='benefics-header'>
        <span className='benefic-span'>
          Beneficios de la Comunidad <b className='text-bold'>VIP</b>
        </span>
        <p className='benefic-paragraph'>
          Siendo parte de la comunidad <b className='text-bold'>VIP</b> tendrás
          acceso a múltiples beneficos y puntos
        </p>
      </div>
      <button
        className='show-benefits-btn'
        onClick={() => navigate('/autenticacion/registro')}
      >
        <img className='show-benefics-icon' src={buttonIcon} />
        <p className='show-benefics-text'>
          Conoce todos nuestros beneficios registrandote →
        </p>
      </button>
    </section>
  );
}
