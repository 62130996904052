import './streaming.css';
import { dataStreaming } from '@data/home/header';
import { useEffect, useState } from 'react';

export default function Streaming() {
  const [animatedAmounts, setAnimatedAmounts] = useState(dataStreaming.map(item => ({ ...item, ammount: 0 })));

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedAmounts(prevAmounts =>
        prevAmounts.map((item, index) => {
          if (item.ammount < dataStreaming[index].ammount) {
            return { ...item, ammount: item.ammount + Math.ceil(dataStreaming[index].ammount / 100) };
          }
          return item;
        })
      );
    }, 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ul className='streaming-list'>
        {animatedAmounts.map((item, index) => (
          <li key={item.title} className='streaming-item'>
            <span className='title-streaming'>{item.title}</span>
            <p className='ammount-streaming'>
              {item.ammount}
              {dataStreaming[index].chart ? `${dataStreaming[index].chart}` : ''}
            </p>
          </li>
        ))}
      </ul>
    </>
  );
}