import vip from '@assets/iconify/vipicon.svg';
import silvercard from '@assets/images/skinMembership.webp';
import login from '@assets/iconify/login.svg';
import facebook from '@assets/iconify/facebook.svg';
import instagram from '@assets/iconify/instagram.svg';
import twitter from '@assets/iconify/twitter.svg';

export const imgFooter = {
  vip,
  silvercard,
  login,
  facebook,
  twitter,
  instagram
};
