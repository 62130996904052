import { Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { PrivateRoutes, DashboardRoutes } from '@interfaces/index';
import { Dashboard } from './index';
import RoutesWithNotFound from '@utils/RoutesWithNotFound';
const Users = lazy(() => import('@components/dashboard/users/Users'));
const Benefits = lazy(() => import('@components/dashboard/benefits/Benefits'));
const Memberships = lazy(
  () => import('@components/dashboard/memberships/Memberships')
);
const User = lazy(() => import('./Users/User'));
const Tags = lazy(() => import('@components/dashboard/tags/Tags'));
import { AuthContextProvider } from 'context';

export default function Private() {
  return (
    <>
      <Suspense fallback='Loading...'>
        <AuthContextProvider>
          <RoutesWithNotFound>
            <Route path={PrivateRoutes.DASHBOARD} element={<Dashboard />}>
              <Route path={DashboardRoutes.USERS} element={<Users />} />
              <Route path={DashboardRoutes.BENEFITS} element={<Benefits />} />
              <Route
                path={DashboardRoutes.MEMBERSHIPS}
                element={<Memberships />}
              />
              <Route path={DashboardRoutes.TAGS} element={<Tags />} />
            </Route>
            <Route path={PrivateRoutes.USER} element={<User />} />
          </RoutesWithNotFound>
        </AuthContextProvider>
      </Suspense>
    </>
  );
}
